:root {
    --dhlBasicOrange-bg-color: #ffcc00;
    --dhlLightOrange-bg-color: #ffeead;
  }

.linear-gradient{
    background: linear-gradient(
    var(--dhlBasicOrange-bg-color),
    var(--dhlLightOrange-bg-color)
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}