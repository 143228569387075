.code-input {
  border: 0px solid #000;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  color: #000;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  padding: 0.4em;
}

.code-input::placeholder {
  color: #323232;

}
